.wrap {
  margin-top: 90px;
  margin-bottom: 90px;
}

.title {
  font-size: 42px;
  font-weight: bold;
  text-align: center;
}

.desc {
  font-weight: 300;
  font-size: 18px;
  text-align: center;
}

.reward {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
}

.rewardTitle {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 30px;
}

.info {
  width: 50%;
  font-size: 16px;
  font-weight: 400;
}

.info p {
  margin-bottom: 20px;
}

.illustration {
  width: 50%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illustration img {
  max-width: 400px;
  width: 100%;
}

.list {
  list-style: none;
  padding-left: 0;
}

.list li {
  margin-bottom: 10px;
  position: relative;
  padding-left: calc(1em + 10px);
}

.list li::before {
  content: "";
  background: #0037FF;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-left: -1em;
  position: absolute;
  /* top: calc(50% - (1em / 2)); */
  top: 5px;
  left: 1em;
}

.show {
  color: #0037FF;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #0037FF;
  padding: 0;
  line-height: 1em;
  cursor: pointer;
  display: block;
}

a.btnJoin {
  background-color: #0037ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  display: inline-block;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 990px) {
  .reward {
    flex-direction: column;
  }
  .illustration {
    width: 100%;
    margin-bottom: 30px;
    order: 1;
  }
  .info {
    width: 100%;
    order: 2;
  }
}