.wrap {
  margin-top: 90px;
  margin-bottom: 90px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  /* text-transform: uppercase; */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.illustration {
  width: 50%;
  text-align: center;
}

.illustration img {
  max-width: 400px;
}

.steps {
  width: 50%;
  font-size: 16px;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #F2F2F2;
  width: 46px;
  height: 46px;
  font-weight: bold;
  border-radius: 50%;
  font-size: 24px;
  margin-right: 20px;
}

@media (max-width: 990px) {
  .container{
    flex-direction: column;
  }
  .illustration {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 50px;
    max-width: 100vw;
  }
  .steps{
    width: 100%;
  }
}