.header {
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  height: 650px;
  min-height: calc(100vh - 65px - 20px);
  padding-top: 20px;
  background-image: url("../../img/header-bg.svg");
  background-repeat: repeat-x;
  background-size: cover;
  background-position-y: bottom;
  position: relative;
  text-align: center;
}

.header a {
  color: #0037ff;
}

.logo {
  margin-bottom: 60px;
}

.mainTitle {
  font-weight: bold;
  font-size: 56px;
  text-transform: uppercase;
}

.subTitle {
  font-size: 42px;
}

.desc {
  font-weight: 300;
  font-size: 18px;
}

a.btnJoin {
  background-color: #0037ff;
  text-transform: uppercase;
  font-size: 16px;
  padding: 8px 30px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 10px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

@media (max-width: 780px) {
  .logo {
    margin-bottom: 40px;
  }
  .header {
    margin-left: -20px;
    margin-right: -20px;
  }
  .mainTitle {
    font-size: 36px;
  }
  .subTitle {
    font-size: 28px;
  }
  .desc {
    padding-left: 10px;
    padding-right: 10px;
  }
}